const PubSub = window.PubSub;
const $ = window.jQuery;

class MultiTabs {
	constructor() {
        $.fn.reverse = [].reverse;
		PubSub.subscribe('ready', this.init.bind(this));
	}
	init() {
        $('.component.multi-tabs-block').each(function (index, element) {
            var component = $(element);
            var tabs = component.find('.tabs .tab');
            var mobileTabs = [];
            // var select = component.find('select');
            var targets = [];

            // for each tab, find the targeted component and MOVE it after this component
            tabs.reverse().each(function (index, element) {
                var tab = $(element);
                var target_id = tab.attr('data-target');
                var target_anchor = $('#' + target_id);
                var target = target_anchor.parents('.component');

                targets.push(target);

                if (target.length) {
                    target.insertAfter(component);
                    
                    // then hide it if tab not selected
                    if (!tab.hasClass('tab--selected')) {
                        target.hide();
                    }
                } else {
                    console.error('Tab target (' + target_id + ') not found.');
                }
            });

            tabs.reverse().each(function (index, element) {
                var tab = $(element);
                var target_id = tab.attr('data-target');
                var target_anchor = $('#' + target_id);
                var target = target_anchor.parents('.component');

                // generate a mobile tab for accordian view
                var mobileTab = $('<div></div>')
                    .addClass('multi-tabs-block__mobile-tab')
                    .attr('data-target', target_id)
                    .append($('<p></p>').html(tab.html()))
                    .append($('<button></button>').addClass('toggle'));   
                    
                if (index == 0) {
                    mobileTab.addClass('opened');
                }
                    
                mobileTab.on('click', function (event) {
                    // ignore clicks on open tabs, we ain't that kind of accordion
                    if (mobileTab.hasClass('opened')) {
                        mobileTab.removeClass('opened');
                        target.slideUp();
                        return;
                    }

                    event.preventDefault();
                    // targets.forEach(function (target) { target.slideUp() });
                    mobileTabs.forEach(function (tab) { tab.removeClass('opened') });
                    target.find('.slick-slider').css('visibility', 'hidden');
                    target.slideDown(function() {
                        target.find('.slick-slider').slick('setPosition');
                        target.find('.slick-slider').css('visibility', '');
                    });
                    mobileTab.addClass('opened');
                    tabs.removeClass('tab--selected');
                    component.find('.tabs .tab[data-target="' + target_id + '"]').addClass('tab--selected');
                });

                if (target.length) {
                    mobileTab.insertBefore(target);
                    mobileTabs.push(mobileTab);
                }
            });

            function changeTab(target) {
                tabs.removeClass('tab--selected');
                component.find('.tabs .tab[data-target="' + target + '"]').addClass('tab--selected');

                // hide all tab panels
                targets.forEach(function (element) {
                    $(element).hide();
                });

                var target_anchor = $('#' + target);
                target_anchor.parents('.component').show();
            }

            tabs.on('click', function (event) {
                event.preventDefault();
                // select.val($(this).attr('data-target')).trigger('change');
                changeTab($(this).attr('data-target'));
            });

            // select.on('change', function (event) {
            //     event.preventDefault();
            //     changeTab(select.val());
            // });
        });
    }
}

export default new MultiTabs();